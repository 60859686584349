import React, { useEffect } from "react";
import Footer from "../../components/footer";
import Navbar from "../../components/navbar";
import {
  GameImage,
  LightBlackLeftChevronIcon,
  WhiteRightChevronIcon,
  RightBlackChevronIcon,
  HangmanSuccess,
  HangManGameImg,
  HangManWhiteGameImg,
  TriviaGameImg,
  SudokuGameImg,
} from "../../resources/images/images";
import { useAppData } from "../../providers/AppDataProvider";
import Button from "../../components/button";
import GameCard from "../../components/gamecard";
import ProgressBar from "../../components/progressbar";
import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";

const GamesListPage = () => {
  const navigate = useNavigate();
  const { strings } = useAppData();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const renderPopularGames = () => {
    return (
      <div className={styles.middleViewMainContainerStyles}>
        <div className={styles.middleTopContainerStyle}>
          <p className={styles.middleHeaderTextStyle}>
            {strings.gamesPage.popularGames}
          </p>
          <div className={styles.middleDescContainerStyle}>
            <p className={styles.middleDescTextStyle}>
              {strings.gamesPage.popularDesc}
            </p>
            {/* <div className={styles.middleArrowContainerStyle}>
              <div className={styles.chevronIconWrapperStyle}>
                <img
                  src={LightBlackLeftChevronIcon}
                  className={styles.imgStyle}
                />
              </div>
              <div className={styles.buttonArrContainerStyle}>
                <div className={styles.chevronIconWrapperStyle}>
                  <img
                    src={RightBlackChevronIcon}
                    className={styles.imgStyle}
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {renderPopularCardsView()}
        {/* <div className={styles.progressContainerStylw}>
          <ProgressBar
            progressBarStyle={styles.progressBarSTyle}
            progressInsideBardStyle={styles.progressInsideBarSTyle}
          />
        </div> */}
      </div>
    );
  };

  const renderPopularCardsView = () => {
    return (
      <div className={styles.gamesCardMainContainerStyle}>
        <div className={styles.gameCardOuterContainerStyle}>
          <GameCard
            gameImgUri={HangManWhiteGameImg}
            onClick={() => navigate("/gamesscreen", { state: "hangman" })}
            gameName={"HangMan"}
            gameDesc={strings.gamesPage.gameDesc}
            cardContainerStyle={styles.gameCardContainerStyle}
          />
          <GameCard
            gameImgUri={TriviaGameImg}
            gameDesc={strings.gamesPage.gameDesc}
            cardContainerStyle={styles.gameCardContainerStyle}
            gameName={"Trivia"}
            onClick={() => navigate("/gamesscreen", { state: "trivia" })}
          />
        </div>
        <div className={styles.gameCardOuterContainerStyle}>
          <GameCard
            gameImgUri={SudokuGameImg}
            gameDesc={strings.gamesPage.gameDesc}
            cardContainerStyle={styles.gameCardContainerStyle}
            gameName={"Sudoku"}
            onClick={() => navigate("/gamesscreen", { state: "sudoku" })}
          />
          {/* <GameCard
            gameImgUri={TriviaGameImg}
            gameDesc={strings.gamesPage.gameDesc}
            cardContainerStyle={styles.gameCardContainerStyle}
            gameName={"Trivia"}
            onClick={() => navigate("/gamesscreen", { state: "trivia" })}
          /> */}
        </div>
      </div>
    );
  };
  const renderMostPlayedGames = () => {
    return (
      <div className={styles.middleViewMainContainerStyles}>
        <div className={styles.middleTopContainerStyle}>
          <p className={styles.middleHeaderTextStyle}>
            {strings.gamesPage.mostPlayedGames}
          </p>
          <div className={styles.middleDescContainerStyle}>
            <p className={styles.middleDescTextStyle}>
              {strings.gamesPage.popularDesc}
            </p>
            <div className={styles.middleArrowContainerStyle}>
              <div className={styles.chevronIconWrapperStyle}>
                <img
                  src={LightBlackLeftChevronIcon}
                  className={styles.imgStyle}
                />
              </div>
              <div className={styles.buttonArrContainerStyle}>
                <div className={styles.chevronIconWrapperStyle}>
                  <img
                    src={RightBlackChevronIcon}
                    className={styles.imgStyle}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderMostPlayedGameCards()}
        <div className={styles.progressContainerStylw}>
          <ProgressBar
            progressBarStyle={styles.progressBarSTyle}
            progressInsideBardStyle={styles.progressInsideBarSTyle}
          />
        </div>
      </div>
    );
  };
  const renderMostPlayedGameCards = () => {
    return (
      <div className={styles.gamesCardMainContainerStyle}>
        <div className={styles.gameCardOuterContainerStyle}>
          <GameCard
            gameImgUri={HangManGameImg}
            onClick={() => navigate("/gamesscreen", { state: "hangman" })}
            gameName={"HangMan"}
            gameDesc={strings.gamesPage.gameDesc}
            cardContainerStyle={styles.gameMostPlayedCardContainerStyle}
            gameNameTextStyle={styles.nameTextStyle}
            gameDescTextStyle={styles.descTextStyle}
            buttonStyle={styles.btnStyle}
          />
        </div>
        <div className={styles.gameCardOuterContainerStyle}>
          <GameCard
            gameImgUri={TriviaGameImg}
            gameDesc={strings.gamesPage.gameDesc}
            cardContainerStyle={styles.gameMostPlayedCardContainerStyle}
            gameNameTextStyle={styles.nameTextStyle}
            gameDescTextStyle={styles.descTextStyle}
            buttonStyle={styles.btnStyle}
            gameName={"Trivia"}
            onClick={() => navigate("/gamesscreen", { state: "trivia" })}
          />
        </div>
        <div className={styles.gameCardOuterContainerStyle}>
          <GameCard
            gameImgUri={SudokuGameImg}
            gameDesc={strings.gamesPage.gameDesc}
            cardContainerStyle={styles.gameMostPlayedCardContainerStyle}
            gameNameTextStyle={styles.nameTextStyle}
            gameDescTextStyle={styles.descTextStyle}
            buttonStyle={styles.btnStyle}
            gameName={"Sudoku"}
            onClick={() => navigate("/gamesscreen", { state: "sudoku" })}
          />
        </div>
      </div>
    );
  };
  const renderMoreGames = () => {
    return (
      <div className={styles.middleViewMainContainerStyles}>
        <div className={styles.middleTopContainerStyle}>
          <p className={styles.middleHeaderTextStyle}>
            {strings.gamesPage.moreGames}
          </p>
          <div className={styles.middleDescContainerStyle}>
            <p className={styles.middleDescTextStyle}>
              {strings.gamesPage.popularDesc}
            </p>
            <div className={styles.middleArrowContainerStyle}>
              <div className={styles.chevronIconWrapperStyle}>
                <img
                  src={LightBlackLeftChevronIcon}
                  className={styles.imgStyle}
                />
              </div>
              <div className={styles.buttonArrContainerStyle}>
                <div className={styles.chevronIconWrapperStyle}>
                  <img
                    src={RightBlackChevronIcon}
                    className={styles.imgStyle}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderMoreGamesCards()}
        <div className={styles.progressContainerStylw}>
          <ProgressBar
            progressBarStyle={styles.progressBarSTyle}
            progressInsideBardStyle={styles.progressInsideBarSTyle}
          />
        </div>
      </div>
    );
  };
  const renderMoreGamesCards = () => {
    return (
      <div className={styles.gamesCardMainContainerStyle}>
        <div className={styles.gameCardOuterContainerStyle}>
          <GameCard
            gameImgUri={HangManGameImg}
            gameName={strings.gamesPage.gameName}
            gameDesc={strings.gamesPage.gameDesc}
            gameImgWrapperStyle={styles.imgWrapperStyle}
            cardContainerStyle={styles.gameMoreGamesContainerStyle}
            gameNameContainerStyle={styles.gameNameContainerStyle}
            gameNameTextStyle={styles.nameTextStyle}
            gameDescTextStyle={styles.descTextStyle}
            buttonStyle={styles.moreCardBtnStyle}
            onClick={() => navigate("/gamesscreen", { state: "hangman" })}
          />
        </div>
        <div className={styles.gameCardOuterContainerStyle}>
          <GameCard
            gameImgUri={TriviaGameImg}
            gameName={"Trivia"}
            onClick={() => navigate("/gamesscreen", { state: "trivia" })}
            gameDesc={strings.gamesPage.gameDesc}
            gameImgWrapperStyle={styles.imgWrapperStyle}
            cardContainerStyle={styles.gameMoreGamesContainerStyle}
            gameNameContainerStyle={styles.gameNameContainerStyle}
            gameNameTextStyle={styles.nameTextStyle}
            gameDescTextStyle={styles.descTextStyle}
            buttonStyle={styles.moreCardBtnStyle}
          />
        </div>
      </div>
    );
  };
  return (
    <div>
      <Navbar />
      {renderPopularGames()}
      {renderMostPlayedGames()}
      {/* {renderMoreGames()} */}
      <Footer />
    </div>
  );
};

export default GamesListPage;
