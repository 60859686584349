import React from "react";
import styles from "./styles.module.css";

const Button = (props) => {
  return (
    <button
      className={[props.btnStyle, styles.buttonStyle].join(" ")}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.title}
      {props.imageUri && (
        <div
          className={[
            props.imageOuterWrapperStyle,
            styles.imageOuterContainerStyle,
          ].join(" ")}
        >
          <div
            className={[props.imageWrapperStyle, styles.imageWrapperStyle].join(
              " "
            )}
          >
            <img src={props.imageUri} className={styles.imageStyle} />
          </div>
        </div>
      )}
    </button>
  );
};

export default Button;
