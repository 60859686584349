import React from "react";
import styles from "./styles.module.css";
import {
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
} from "../../resources/images/images";

const WebFooter = () => {
  return (
    <div className={styles.footerContainerStyles}>
      <div className={styles.imageWrapperStyle}>
        <img src={InstagramIcon} className={styles.imageStyle} />
      </div>
      <div className={styles.imageWrapperStyle}>
        <img src={FacebookIcon} className={styles.imageStyle} />
      </div>
      <div className={styles.imageWrapperStyle}>
        <img src={TwitterIcon} className={styles.imageStyle} />
      </div>
    </div>
  );
};

export default WebFooter;
