import React from "react";
import { useAppData } from "../../providers/AppDataProvider";
import styles from "./styles.module.css";
import {
  LinkedInIcon,
  FacebookIcon,
  InstagramIcon,
  MailIcon,
  CallIcon,
} from "../../resources/images/images";

const Footer = () => {
  const { strings } = useAppData();
  const renderDivider = () => {
    return <div className={styles.dividerStyle}></div>;
  };
  const renderSocialIcons = () => {
    return (
      <div className={styles.socialIconsContainerStyle}>
        <div className={styles.iconOuterContainerStyle}>
          <div className={styles.socialIconWrapperStyle}>
            <img src={LinkedInIcon} className={styles.imgStyle} />
          </div>
        </div>
        <div className={styles.iconOuterContainerStyle}>
          <div className={styles.socialIconWrapperStyle}>
            <img src={FacebookIcon} className={styles.imgStyle} />
          </div>
        </div>
        <div className={styles.iconOuterContainerStyle}>
          <div className={styles.socialIconWrapperStyle}>
            <img src={InstagramIcon} className={styles.imgStyle} />
          </div>
        </div>
      </div>
    );
  };
  const renderContactUs = () => {
    return (
      <div className={styles.contactMainContainerStyle}>
        <p className={styles.contactTextStyle}>{strings.footer.contactUs}</p>
        <div className={styles.contactBottomContainerStyle}>
          <div className={styles.contactItemContainerStyle}>
            <div className={styles.contactIconWrapperStyle}>
              <img src={MailIcon} className={styles.imgStyle} />
            </div>
            <p className={styles.mailTextStyle}>{strings.footer.mail}</p>
          </div>
          <div className={styles.contactItemContainerStyle}>
            <div className={styles.contactIconWrapperStyle}>
              <img src={CallIcon} className={styles.imgStyle} />
            </div>
            <p className={styles.mailTextStyle}>{strings.footer.mobile}</p>
          </div>
        </div>
      </div>
    );
  };
  const renderPrivacyView = () => {
    return (
      <div className={styles.privacyMainContainerStyle}>
        <p className={styles.privacyTextStyle}>
          {strings.footer.privacyPolicy}
        </p>
        <p className={styles.privacyTextStyle}>{strings.footer.termsOfUse}</p>
        <p className={styles.copyRightsTextStyle}>
          {strings.footer.copyRights}
        </p>
      </div>
    );
  };
  return (
    <div className={styles.footerMainContainerStyle}>
      <p className={styles.footerHeaderTextStyle}>
        {strings.footer.headerText}
      </p>
      {renderSocialIcons()}
      {renderDivider()}
      {renderContactUs()}
      {renderDivider()}
      {renderPrivacyView()}
    </div>
  );
};

export default Footer;
