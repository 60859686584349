import React, { useContext, createContext, useEffect, useState } from "react";
import { EnglishStrings } from "../resources/strings/english-strings";
import { SwedishStrings } from "../resources/strings/swedish-strings";

export const AppDataContext = createContext({
  strings: EnglishStrings,
  currentLanguage: "",
  setLanguage: (_value) => {
    return null;
  },
});

export const AppDataProvider = (props) => {
  const defaultLang = "EN";
  const [language, setLanguage] = useState(defaultLang);

  const initialize = async () => {
    setLanguage(localStorage.getItem("language") || defaultLang);
  };

  useEffect(() => {
    initialize();
  }, []);

  const strings = () => {
    switch (language) {
      case "EN":
        return EnglishStrings;
      case "SE":
        return SwedishStrings;
      default:
        return EnglishStrings;
    }
  };

  return (
    <AppDataContext.Provider
      value={{
        strings: strings(),
        currentLanguage: language,
        setLanguage: (value) => {
          localStorage.setItem("language", value);
          setLanguage(value);
        },
      }}
    >
      {props.children}
    </AppDataContext.Provider>
  );
};

export const useAppData = () => useContext(AppDataContext);
