import React from "react";
import styles from "./styles.module.css";

const TextInput = (props) => {
  return (
    <div className={styles.inputContainerStyle}>
      <p className={styles.labelTextStyle}>{props.label}</p>
      <input
        type={props.type}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        className={styles.inputStyle}
      />
    </div>
  );
};

export default TextInput;
