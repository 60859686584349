import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  StarEmptyIcon,
  StarYellowIcon,
  ReloadIcon,
  CloseIcon,
  ScoreCoinsIcon,
  Hangman1,
  Hangman2,
  Hangman3,
  Hangman4,
  Hangman5,
  Hangman6,
  Hangman7,
  HangmanSuccess,
  BtnCrossIcon,
  BlackCloseIcon,
  PlayerImage,
  RightBlackChevronIcon,
  LightBlackLeftChevronIcon,
  HangManGameImg,
} from "../../resources/images/images";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import PopOver from "../../components/popover";
import TextInput from "../../components/textinput";
import styles from "./styles.module.css";

const HangmanGame = () => {
  const [word, setWord] = useState("");
  const navigate = useNavigate();
  const [guessedLetters, setGuessedLetters] = useState([]);
  const [remainingGuesses, setRemainingGuesses] = useState(0);
  const [timer, setTimer] = useState(30);
  const [reset, setReset] = useState(false);
  const [maxguesses, setMaxGuesses] = useState(6);
  const [popOver, setPopOver] = useState(false);
  const [hangImg, setHangImg] = useState();
  const [step, setStep] = useState(1);
  const [score, setScore] = useState(0);
  const [user, setUser] = useState({
    email: "",
    mobileNo: "",
    name: "",
  });

  const words = [
    "apple",
    "banana",
    "mango",
    "strawberry",
    "papaya",
    "jackfriut",
    "cherry",
    "guava",
    "kiwi",
    "grapes",
    "watermelon",
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getRandomWord();
  }, []);
  useEffect(() => {
    if (remainingGuesses === 0) {
      setHangImg(Hangman1);
    } else if (remainingGuesses === 1) {
      setHangImg(Hangman2);
    } else if (remainingGuesses === 2) {
      setHangImg(Hangman3);
    } else if (remainingGuesses === 3) {
      setHangImg(Hangman4);
    } else if (remainingGuesses === 4) {
      setHangImg(Hangman5);
    } else if (remainingGuesses === 5) {
      setHangImg(Hangman6);
    } else if (remainingGuesses === 6) {
      setHangImg(Hangman7);
    }
  }, [remainingGuesses]);

  const timerToString = useCallback(() => {
    // let hours = ('0' + Math.floor(timer/3600)).slice(-2);
    let minutes = ("0" + Math.floor(timer / 60)).slice(-2);
    let seconds = ("0" + (timer % 60)).slice(-2);
    return /*hours + ":" +*/ minutes + ":" + seconds;
  }, [timer]);
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setPopOver(true);
    }
  }, [reset]);

  useEffect(() => {
    if (popOver) {
      let timeOut = null;
      if (timer > 0) {
        timeOut = setTimeout(() => {
          setTimer((prev) => prev - 1);
        }, 1000);
      }
      return () => {
        if (reset) {
          clearTimeout(timeOut);
        }
      };
    }
  }, [timer, reset, popOver]);

  const getRandomWord = () => {
    const randomIndex = Math.floor(Math.random() * words.length);
    setWord(words[randomIndex].toLowerCase());
  };

  const handleGuess = (letter) => {
    const formattedLetter = letter.toLowerCase();
    if (!guessedLetters.includes(formattedLetter)) {
      setGuessedLetters([...guessedLetters, formattedLetter]);
      if (word.includes(formattedLetter)) {
        setScore(score + 1);
      }
      if (!word.includes(formattedLetter)) {
        setRemainingGuesses(remainingGuesses + 1);
      }
    }
  };

  const isGameOver = () => {
    return remainingGuesses >= maxguesses || isWordGuessed() || timer <= 0;
  };

  const isWordGuessed = () => {
    return word.split("").every((letter) => guessedLetters.includes(letter));
  };

  const displayWord = () => {
    return word.split("").map((letter, index) => (
      <span key={index} className={styles.lettersTextStyle}>
        {guessedLetters.includes(letter) ? letter.toLocaleUpperCase() : "__"}
      </span>
    ));
  };
  const displayAlphabetButtons = () => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    return alphabet.split("").map((letter) => (
      <button
        key={letter}
        className={styles.btnStyles}
        onClick={() => handleGuess(letter)}
        disabled={guessedLetters.includes(letter) || isGameOver()}
      >
        {guessedLetters.includes(letter) && !word.split("").includes(letter) ? (
          <div className={styles.iconWrapperStyle}>
            <img src={BtnCrossIcon} className={styles.imgStyle} />
          </div>
        ) : (
          letter.toLocaleUpperCase()
        )}
      </button>
    ));
  };

  const handleRestart = () => {
    setGuessedLetters([]);
    setRemainingGuesses(0);
    setScore(0);
    getRandomWord();
    setReset(!reset);
    setTimer(30);
  };
  const renderTopReloadView = () => {
    return (
      <div className={styles.topContainerStyle}>
        <div
          className={styles.iconWrapperStyle}
          onClick={() => window.location.reload()}
        >
          <img src={ReloadIcon} className={styles.imgStyle} />
        </div>
        <div className={styles.iconWrapperStyle} onClick={() => navigate(-1)}>
          <img src={CloseIcon} className={styles.imgStyle} />
        </div>
      </div>
    );
  };
  const renderScoreContainer = () => {
    return (
      <div className={styles.scoreContainerStyle}>
        <p className={styles.levelTextStyle}>
          {remainingGuesses}
          <span className={styles.totalLevelsTextStyle}>/{maxguesses}</span>
        </p>
        {/* <div className={styles.starsContainerStyle}>
          <div className={styles.starIconWrapperStyle}>
            <img src={StarYellowIcon} className={styles.imgStyle} />
          </div>
          <div className={styles.starIconWrapperStyle}>
            <img src={StarYellowIcon} className={styles.imgStyle} />
          </div>
          <div className={styles.starIconWrapperStyle}>
            <img src={StarEmptyIcon} className={styles.imgStyle} />
          </div>
        </div>
        <div className={styles.scorTextContainerStyle}>
          <div className={styles.starIconWrapperStyle}>
            <img src={ScoreCoinsIcon} className={styles.imgStyle} />
          </div>
          <p className={styles.scoreTextStyle}>0</p>
        </div> */}
      </div>
    );
  };
  const renderGameView = () => {
    return (
      <div className={styles.gameViewContainerStyle}>
        <div className={styles.hangManImgWrapperStyle}>
          <img
            src={isWordGuessed() ? HangmanSuccess : hangImg}
            className={styles.imgStyle}
          />
        </div>
        <div className={styles.gameLevelNameContainerStyle}>
          <p className={styles.gameLevelNameStyle}>Fruits</p>
        </div>
        <div className={styles.wordContainerStyle}>{displayWord()}</div>
      </div>
    );
  };
  const renderTimerView = () => {
    return (
      <div className={styles.timerContainerStyle}>
        <p className={styles.timerTextStyle}>
          {isGameOver() ? "00:00" : timerToString()}
        </p>
      </div>
    );
  };
  return (
    <div className={styles.gameContainerStyle}>
      {renderTopReloadView()}
      {renderScoreContainer()}
      {renderGameView()}
      <div className={styles.alphabetBtnsContainerStyle}>
        {displayAlphabetButtons()}
      </div>
      {renderTimerView()}
      {isGameOver() && (
        <PopOver
          children={
            <div className={styles.popoverInsideContainerStyle}>
              <p className={styles.gameTextSTyle}>
                {isWordGuessed() ? "Congratulations! You won!" : "Game Over"}
              </p>
              <div className={styles.btnContainerStyle}>
                <Button
                  title={"Restart"}
                  btnStyle={styles.btnStyle}
                  onClick={handleRestart}
                />
                <Button
                  title={"LeaderBoard"}
                  btnStyle={styles.btnStyle}
                  onClick={() =>
                    navigate("/leaderboard", {
                      state: {
                        score: (score / word.length) * 100,
                        gameName: "Hangman",
                        img: HangManGameImg,
                      },
                    })
                  }
                />
              </div>
            </div>
          }
        />
      )}
      {!popOver && (
        <PopOver
          children={
            <div className={styles.popoverInsideContainerStyle}>
              <div className={styles.closeIconContainerSTyle}>
                <div
                  className={styles.iconWrapperStyle}
                  onClick={() => setPopOver(true)}
                >
                  <img src={BlackCloseIcon} className={styles.imgStyle} />
                </div>
                <div className={styles.popoverHeaderContainerStyle}>
                  <p className={styles.popOverHeaderTextStyle}>
                    Player Details
                  </p>
                  <p className={styles.popOverDescTextStyle}>
                    Unleash Your Potential! Only the Highest Score Guarantees
                    Remarkable Gifts.
                  </p>
                </div>
              </div>
              {step === 1 && (
                <div className={styles.inputContainerStyle}>
                  <TextInput
                    label={"Email (Optional)"}
                    type={"text"}
                    value={user.email}
                    placeholder={"Enter Email ID"}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                  />
                  <TextInput
                    label={"Phone Number"}
                    type={"text"}
                    value={user.mobileNo}
                    placeholder={"Enter Phone number"}
                    onChange={(e) =>
                      setUser({ ...user, mobileNo: e.target.value })
                    }
                  />
                  <p
                    className={styles.skipBlackTextSTyle}
                    onClick={() => {
                      // setPopOver(true);
                      // localStorage.setItem("user", JSON.stringify(user));
                      setStep(step + 1);
                    }}
                  >
                    Skip
                  </p>
                  <Button
                    title={"Next"}
                    btnStyle={styles.btnNextStyle}
                    disabled={
                      user.email !== "" && user.mobileNo !== "" ? false : true
                    }
                    onClick={() => setStep(step + 1)}
                  />
                </div>
              )}
              {step === 2 && (
                <div className={styles.inputContainerStyle}>
                  <div className={styles.playerContainerStyle}>
                    <div className={styles.playerImgWrapperStyle}>
                      <img src={PlayerImage} className={styles.imgStyle} />
                    </div>
                    <div className={styles.playerNameContainerStyle}>
                      <div className={styles.iconWrapperStyle}>
                        <img
                          src={LightBlackLeftChevronIcon}
                          className={styles.imgStyle}
                        />
                      </div>
                      <p>Avathar</p>
                      <div className={styles.iconWrapperStyle}>
                        <img
                          src={RightBlackChevronIcon}
                          className={styles.imgStyle}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <TextInput
                      label={"Display Name"}
                      type={"text"}
                      value={user.name}
                      placeholder={"Enter display Name"}
                      onChange={(e) =>
                        setUser({ ...user, name: e.target.value })
                      }
                    />
                    <p className={styles.displayTextStyle}>
                      Your Display Name will be your Identity in the Game Zone.
                    </p>
                  </div>
                  <p
                    className={styles.skipBlackTextSTyle}
                    onClick={() => {
                      localStorage.setItem("user", JSON.stringify(user));
                      setPopOver(true);
                    }}
                  >
                    Skip
                  </p>
                  <Button
                    title={"Continue"}
                    btnStyle={styles.btnNextStyle}
                    onClick={() => {
                      localStorage.setItem("user", JSON.stringify(user));
                      setPopOver(true);
                    }}
                  />
                </div>
              )}
            </div>
          }
        />
      )}
    </div>
  );
};

export default HangmanGame;
