import React from "react";
import styles from "./styles.module.css";

const Node = (props) => {
  let { cell, handleClickCallback } = props;
  const getCellClassName = (cell) => {
    let { row, column } = cell;
    let className = ` 
    ${row === 2 || row === 5 ? styles.bottomBorderStyle : ""}
    ${column === 2 || column === 5 ? styles.borderStyle : ""}
    ${cell.isValid ? "" : "cell-invalid"}
    ${cell.isModifiable ? "cell-modifiable" : ""}
    ${cell.isHinted ? "cell-hinted" : ""}
    `;
    return className;
  };
  return (
    <div className={getCellClassName(cell)}>
      <td
        className={
          cell.isModifiable
            ? styles.nonModifiableCellStyle
            : styles.cellInputStyle
        }
        onClick={() =>
          handleClickCallback(cell.row, cell.column, cell.isModifiable)
        }
      >
        {cell.value !== 0 && cell.value}
      </td>
    </div>
  );
};

export default Node;
