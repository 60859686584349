import React, { useRef, useEffect } from "react";
import Navbar from "../../components/navbar";
import {
  HomeheroImage,
  HomelineImage,
  BlackDownArrowIcon,
  HomeVocherImage,
  WhiteLeftChevronIcon,
  WhiteRightChevronIcon,
  LightBlackLeftChevronIcon,
  LightBlackRightChevronIcon,
  HangmanSuccess,
  HangManWhiteGameImg,
  HangManGameImg,
  TriviaGameImg,
  SudokuGameImg,
  GameImage,
} from "../../resources/images/images";
import styles from "./styles.module.css";
import { useAppData } from "../../providers/AppDataProvider";
import Button from "../../components/button";
import Footer from "../../components/footer";
import GameCard from "../../components/gamecard";
import VoucherCard from "../../components/vouchercard";
import ProgressBar from "../../components/progressbar";
import { useNavigate } from "react-router-dom";
const HomePage = () => {
  const navigate = useNavigate();
  const { strings } = useAppData();
  const scrollRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const renderTopView = () => {
    return (
      <div className={styles.topViewContainerSTyle}>
        {renderTopHeroView()}
        <Button
          onClick={() =>
            scrollRef.current?.scrollIntoView({ behavior: "smooth" })
          }
          imageUri={BlackDownArrowIcon}
          title={"Play Games"}
        />
        <VoucherCard
          lightText={strings.homePage.headerText2.text2}
          boldText={strings.homePage.headerText2.text1}
          voucherImgUri={HomeVocherImage}
        />
      </div>
    );
  };
  const renderTopHeroView = () => {
    return (
      <div className={styles.topViewTextContainerStyle}>
        <div className={styles.nameContainerStyle}>
          <p className={styles.nameTextStyle}>Fire burgers 🍔</p>
        </div>
        <div className={styles.homeHeroImgWrapperStyle}>
          <img src={HomeheroImage} className={styles.imgStyle} />
        </div>
        <div className={styles.topViewBottomTextContainerStyle}>
          <div className={styles.topViewTextInsideWrapperStyle}>
            <h1 className={styles.headerBoldTextStyle}>
              {strings.homePage.headerText.text1}
              <span className={styles.headerTextStyle}>
                {strings.homePage.headerText.text2}
              </span>
              {strings.homePage.headerText.text3}
            </h1>
            <div className={styles.lineImageWrapperStyle}>
              <img src={HomelineImage} className={styles.imgStyle} />
            </div>
          </div>
          <p className={styles.topViewDescTextStyle}>{strings.homePage.desc}</p>
        </div>
      </div>
    );
  };

  const renderMiddleView = () => {
    return (
      <div ref={scrollRef} className={styles.middleViewMainContainerStyles}>
        <div className={styles.middleTopContainerStyle}>
          <p className={styles.middleHeaderTextStyle}>
            {strings.homePage.popularGames}
          </p>
          <div className={styles.middleDescContainerStyle}>
            <p className={styles.middleDescTextStyle}>
              {strings.homePage.popularDesc}
            </p>
            {/* <div className={styles.middleArrowContainerStyle}>
              <div className={styles.chevronIconWrapperStyle}>
                <img
                  src={LightBlackLeftChevronIcon}
                  className={styles.imgStyle}
                />
              </div>
              <div className={styles.chevronIconWrapperStyle}>
                <img src={WhiteRightChevronIcon} className={styles.imgStyle} />
              </div>
            </div> */}
          </div>
        </div>
        <div className={styles.middleTopContainerStyle}>
          <GameCard
            gameImgUri={HangManWhiteGameImg}
            gameName={strings.homePage.hangMan}
            gameDesc={strings.homePage.gameDesc}
            onClick={() => navigate("/gamesscreen", { state: "hangman" })}
          />
          <GameCard
            gameImgUri={TriviaGameImg}
            gameName={"Trivia"}
            gameDesc={strings.homePage.gameDesc}
            onClick={() => navigate("/gamesscreen", { state: "trivia" })}
          />
          <GameCard
            gameImgUri={SudokuGameImg}
            gameName={"Sudoku"}
            gameDesc={strings.homePage.gameDesc}
            onClick={() => navigate("/gamesscreen", { state: "sudoku" })}
          />
        </div>
        {/* <ProgressBar /> */}
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      {renderTopView()}
      {renderMiddleView()}
      <Footer />
    </div>
  );
};

export default HomePage;
