import React from "react";
import { HomelineImage } from "../../resources/images/images";
import styles from "./styles.module.css";

const RewardCard = (props) => {
  return (
    <div className={styles.rewardContainerStyle}>
      <div className={styles.rewardImgWrapperStyle}>
        <img src={props.imageUri} className={styles.imgStyle} />
      </div>
      <div className={styles.rewardTextContainerStyle}>
        <p className={styles.rewardBoldTextStyle}>
          {props.boldText1}
          <span className={styles.rewardTextStyle}>{props.normalText1}</span>
          {props.boldText2}
        </p>
        <div className={styles.lineWrapperStyle}>
          <img src={HomelineImage} className={styles.imgStyle} />
        </div>
      </div>
    </div>
  );
};

export default RewardCard;
