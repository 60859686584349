import React from "react";
import styles from "./styles.module.css";
import Button from "../button";
import { BlackCloseIcon } from "../../resources/images/images";

const NoSolutionFoundModal = ({ closeModal }) => {
  return (
    <div className={styles.noSolutionFoundModalStyle}>
      <div className={styles.modalContainerStyle}>
        <div className={styles.modalCloseBtnContainerStyle}>
          <div className={styles.iconWrapperStyle} onClick={closeModal}>
            <img src={BlackCloseIcon} className={styles.imgStyle} />
          </div>
        </div>
        <div>
          <h1 className={styles.modalTitleStyle}>No Solution Found</h1>
        </div>
        <div className={styles.modalBodyStyle}>
          <p>
            The Current Grid Doesnot have any solution, please change some cell
            values.
          </p>
        </div>
        <div className={styles.modalFooterStyle}>
          <Button
            onClick={closeModal}
            btnStyle={styles.btnStyle}
            title="Continue"
          />
        </div>
      </div>
    </div>
  );
};

export default NoSolutionFoundModal;
