import React, { useEffect, useRef } from "react";

import lottie from "lottie-web";
import easyDifficultyAnimationData from "../../assets/animations/DifficultyAnimations/EasyDifficultyAnimation/EasyDifficultyAnimation.json";
import mediumDifficultyAnimationData from "../../assets/animations/DifficultyAnimations/MediumDifficultyAnimation/MediumDifficultyAnimation.json";
import hardDifficultyAnimationData from "../../assets/animations/DifficultyAnimations/HardDifficultyAnimation/HardDifficultyAnimation.json";

import styles from "./styles.module.css";
import Button from "../button";
import { BlackCloseIcon } from "../../resources/images/images";

const DifficultySelectionModal = ({
  closeModal,
  handleNewGame,
  easyMaxEmptyCells,
  mediumMaxEmptyCells,
  hardMaxEmptyCells,
}) => {
  console.log("rendering....");
  const bodyContainer = useRef(null);

  // Use Effect for the animation
  useEffect(() => {
    let defaultOptions = { renderer: "svg", loop: true, autoplay: true };

    // Easy Difficulty Animation
    let easyDifficultyAnimationContainer = document.getElementById(
      "easyDifficultyAnimation"
    );
    const easyDifficultyAnimation = lottie.loadAnimation({
      container: easyDifficultyAnimationContainer,
      animationData: easyDifficultyAnimationData,
      ...defaultOptions,
    });

    // Medium Difficulty Animation
    let mediumDifficultyAnimationContainer = document.getElementById(
      "mediumDifficultyAnimation"
    );
    const mediumDifficultyAnimation = lottie.loadAnimation({
      container: mediumDifficultyAnimationContainer,
      animationData: mediumDifficultyAnimationData,
      ...defaultOptions,
    });

    // Hard Difficulty Animation
    let hardDifficultyAnimationContainer = document.getElementById(
      "hardDifficultyAnimation"
    );
    const hardDifficultyAnimation = lottie.loadAnimation({
      container: hardDifficultyAnimationContainer,
      animationData: hardDifficultyAnimationData,
      ...defaultOptions,
    });

    bodyContainer.current.style.visibility = "visible";

    return () => {
      easyDifficultyAnimation.destroy();
      mediumDifficultyAnimation.destroy();
      hardDifficultyAnimation.destroy();
    }; // Clean up function
  }, []);

  return (
    <div className={styles.difficultySelectionModalStyle}>
      <div className={styles.modalContainerStyle}>
        <div className={styles.modalCloseBtnContainerStyle}>
          <div className={styles.iconWrapperStyle} onClick={closeModal}>
            <img src={BlackCloseIcon} className={styles.imgStyle} />
          </div>
        </div>
        <div>
          <h1 className={styles.modalTitleStyle}>Difficulty Mode</h1>
        </div>
        <div className={styles.difficultyModalBodyStyle} ref={bodyContainer}>
          <div className={styles.difficultySelectionContainerStyle}>
            <div
              className={styles.difficultyEasyStyle}
              onClick={() => handleNewGame(easyMaxEmptyCells)}
            >
              <div
                className={styles.animationStyle}
                id="easyDifficultyAnimation"
              ></div>
              <p className={styles.textStyle}>Easy</p>
            </div>

            <div
              className={styles.difficultyMediumStyle}
              onClick={() => handleNewGame(mediumMaxEmptyCells)}
            >
              <div
                className={styles.animationStyle}
                id="mediumDifficultyAnimation"
              ></div>
              <p className={styles.textStyle}>Medium</p>
            </div>

            <div
              className={styles.difficultyHardStyle}
              onClick={() => handleNewGame(hardMaxEmptyCells)}
            >
              <div
                className={styles.animationStyle}
                id="hardDifficultyAnimation"
              ></div>
              <p className={styles.textStyle}>Hard</p>
            </div>
          </div>
        </div>
        <div className={styles.modalFooterStyle}>
          <Button
            onClick={closeModal}
            btnStyle={styles.btnStyle}
            title="Back"
          />
        </div>
      </div>
    </div>
  );
};

export default DifficultySelectionModal;
