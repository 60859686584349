import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import { useAppData } from "../../providers/AppDataProvider";
import {
  GameImage,
  WinnerIcon,
  ScoreCoinsIcon,
  PlayerImage,
  LeaderBoardLine1,
  LeaderBoardLine2,
} from "../../resources/images/images";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

const LeaderBoard = () => {
  const { strings } = useAppData();
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const data = [
    {
      name: "user1",
      score: 50,
    },
    {
      name: "user2",
      score: 25,
    },
    {
      name: "user3",
      score: 59,
    },
    {
      name: userData?.name !== "" ? userData?.name : "Unkonwn",
      score: Math.floor(location.state ? location.state.score : 0),
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUserData(JSON.parse(localStorage.getItem("user")));
    }
  }, []);
  window.onpopstate = () => {
    navigate("/gameszone");
  };
  const renderGamePlayView = () => {
    return (
      <div className={styles.playGameContainerStyle}>
        <div className={styles.gameImgWrapperStyle}>
          <img src={location?.state.img} className={styles.imgStyle} />
        </div>
        <div className={styles.gameTextContainerStyle}>
          <p className={styles.gameNameTextStyle}>{location?.state.gameName}</p>
          <p className={styles.gameDescTextStyle}>
            {strings.leaderBoard.scoreBoard}
          </p>
        </div>
      </div>
    );
  };
  const renderPlayerView = () => {
    return (
      <div className={styles.playerContainerStyle}>
        <div className={styles.playerImageContainerStyle}>
          <div className={styles.lineWrapperStyle1}>
            <img src={LeaderBoardLine1} className={styles.imgStyle} />
          </div>
          <div className={styles.playerImgWrapperStyle}>
            <img src={PlayerImage} className={styles.imgStyle} />
          </div>
          <div className={styles.lineWrapperStyle2}>
            <img src={LeaderBoardLine2} className={styles.imgStyle} />
          </div>
        </div>
        <p className={styles.playerNameTextStyle}>
          {userData?.name !== "" ? userData?.name : "Unkonwn"}
        </p>
        <div className={styles.gameScoreContainerStyle}>
          <p className={styles.scoreTextStyle}>{strings.leaderBoard.score}:</p>
          {/* <div className={styles.coinsWrapperStyle}>
            <img src={ScoreCoinsIcon} className={styles.imgStyle} />
          </div> */}
          <p className={styles.scoreNoTextStyle}>
            {location.state.score
              ? Math.floor(location.state.score)
              : strings.leaderBoard.scoreNO}
          </p>
          <p className={styles.scoreTextStyle}>points</p>
        </div>
      </div>
    );
  };
  const renderDivider = () => {
    return <div className={styles.dividerStyle}></div>;
  };
  const renderLeaderBoardTable = () => {
    return (
      <div className={styles.scroeBoardContainerStyle}>
        <div className={styles.headerContainerStyle}>
          <p className={styles.headerTextStyle}>
            {strings.leaderBoard.topPerformanceText}
          </p>
          <p className={styles.descTextStyle}>{strings.leaderBoard.congrats}</p>
        </div>
        <div className={styles.leaderTableContainerStyle}>
          <div className={styles.tabContainerStyle}>
            <div className={styles.selectedTabStyle}>
              <p className={styles.tabSelectedTextStyle}>
                {strings.leaderBoard.all}
              </p>
            </div>
            <p className={styles.tabTextStyle}>{strings.leaderBoard.daily}</p>
            <p className={styles.tabTextStyle}>{strings.leaderBoard.weekly}</p>
            <p className={styles.tabTextStyle}>{strings.leaderBoard.monthly}</p>
          </div>
          {renderDivider()}

          <table className={styles.tableContainerStyle}>
            <tr className={styles.rowContainerStyle}>
              <td className={styles.tableHeaderCellStyle}>
                {strings.leaderBoard.rank}
              </td>
              <td className={styles.tableHeaderCellStyle}>
                {strings.leaderBoard.playerName}
              </td>
              <td className={styles.tableHeaderCellStyle}>
                {strings.leaderBoard.score}
              </td>
            </tr>
            {data
              .sort((a, b) => parseFloat(b.score) - parseFloat(a.score))
              .map((item, index) => {
                return (
                  <tr
                    className={
                      item.rank === 4
                        ? styles.rowSelectedContainerStyle
                        : styles.rowContainerStyle
                    }
                    key={index}
                  >
                    <td className={styles.tableRankCellStyle}>
                      {item.rank === 1 ? (
                        <div className={styles.winnerWrapperStyle}>
                          <img src={WinnerIcon} className={styles.imgStyle} />
                        </div>
                      ) : (
                        <div className={styles.rankContainerStyle}>
                          {index + 1}
                        </div>
                      )}
                    </td>
                    <td className={styles.tableCellStyle}>{item.name}</td>
                    <td className={styles.tableScoreCellStyle}>
                      {/* <div className={styles.coinsWrapperStyle}>
                        <img src={ScoreCoinsIcon} className={styles.imgStyle} />
                      </div> */}
                      {item.score}
                      {/* <p className={styles.scoreTextStyle}>points</p> */}
                    </td>
                  </tr>
                );
              })}
          </table>
        </div>
      </div>
    );
  };
  return (
    <div>
      <Navbar />
      <div className={styles.leaderBoardContainerStyle}>
        {renderGamePlayView()}
        {renderPlayerView()}
        {renderDivider()}
        {renderLeaderBoardTable()}
      </div>
      <Footer />
    </div>
  );
};

export default LeaderBoard;
