import React from "react";
import styles from "./styles.module.css";

const ProgressBar = (props) => {
  return (
    <div
      className={[props.progressBarStyle, styles.progressBarSTyle].join(" ")}
    >
      <div
        className={[
          props.progressInsideBardStyle,
          styles.progressInsideBarSTyle,
        ].join(" ")}
      ></div>
    </div>
  );
};

export default ProgressBar;
