import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import {
  WebPage,
  HomePage,
  GamesListPage,
  GamePage,
  LeaderBoard,
  AboutPage,
  HangmanGame,
  SudokuGame,
  TriviaGame,
} from "../pages";
import useWindowDimensions from "../hooks/useWindowDimensions";
const AppRoutes = () => {
  const { width } = useWindowDimensions();
  return useRoutes([
    // {
    //   path: "/home",
    //   element: <WebPage />,
    // },
    {
      path: "/",
      element: width > 428 ? <WebPage /> : <HomePage />,
    },
    {
      path: "/gameszone",
      element: <GamesListPage />,
    },
    {
      path: "/gamesscreen",
      element: <GamePage />,
    },
    {
      path: "/leaderboard",
      element: <LeaderBoard />,
    },
    {
      path: "/aboutpage",
      element: <AboutPage />,
    },
    {
      path: "/hangmangame",
      element: <HangmanGame />,
    },
    {
      path: "/sudokugame",
      element: <SudokuGame />,
    },
    {
      path: "/triviagame",
      element: <TriviaGame />,
    },
  ]);
};
export default AppRoutes;
