import React, { useState, useEffect, useCallback } from "react";
import {
  ReloadIcon,
  CloseIcon,
  ScoreCoinsIcon,
  LightBlackLeftChevronIcon,
  RightBlackChevronIcon,
  PlayerImage,
  BlackCloseIcon,
  GameImage,
  TriviaGameImg,
} from "../../resources/images/images";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button";
import TextInput from "../../components/textinput";
import PopOver from "../../components/popover";
import styles from "./styles.module.css";

const TriviaGame = () => {
  const navigate = useNavigate();
  const [timer, setTimer] = useState(30);
  const [indexNo, setIndexNo] = useState(0);
  const [answer, setAnswer] = useState("");
  const [ansView, setAnsView] = useState(false);
  const [step, setStep] = useState(1);
  const [score, setScore] = useState(0);
  const [reset, setReset] = useState(false);
  const [popOver, setPopOver] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [user, setUser] = useState({
    email: "",
    mobileNo: "",
    name: "",
  });
  const questions = [
    {
      que: "Which country has the largest population?",
      ans: "India",
      ansChoise: ["China", "Russia", "India", "America"],
    },
    {
      que: "Chemical formula of water?",
      ans: "H2O",
      ansChoise: ["H2O2", "HO", "H2O", "HO2"],
    },
    {
      que: "Father of Computer ?",
      ans: "Charles Babbage",
      ansChoise: ["Charles Babbage", "George", "Aristotle", "Louis Pascher"],
    },
    {
      que: "Who invented 0 (Zero)?",
      ans: "Aryabhatta",
      ansChoise: [
        "Aryabhatta",
        "Baskar Acharya",
        "C V Raman",
        "Srinivas Ramanujan",
      ],
    },
    {
      que: "Tallest Mountain in the World ?",
      ans: "Mount Everest",
      ansChoise: ["Cho Oyu", "K2", "Makalu", "Mount Everest"],
    },
  ];
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setPopOver(true);
    }
  }, []);
  const timerToString = useCallback(() => {
    // let hours = ('0' + Math.floor(timer/3600)).slice(-2);
    let minutes = ("0" + Math.floor(timer / 60)).slice(-2);
    let seconds = ("0" + (timer % 60)).slice(-2);
    return /*hours + ":" +*/ minutes + ":" + seconds;
  }, [timer]);

  useEffect(() => {
    if (popOver) {
      let timeOut = null;
      if (timer > 0) {
        timeOut = setTimeout(() => {
          setTimer((prev) => prev - 1);
        }, 1000);
      }
      return () => {
        if (answer === "") {
          clearTimeout(timeOut);
        }
      };
    }
  }, [timer, popOver]);
  useEffect(() => {
    if ((timer <= 0 && indexNo < questions.length - 1) || answer !== "") {
      setTimeout(() => {
        setIndexNo(indexNo + 1);
        setAnswer("");
        setTimer(30);
      }, 1000);
    } else if (
      questions.length - 1 === indexNo &&
      timer <= 0 &&
      answer === ""
    ) {
      setGameOver(true);
    } else if (questions.length === indexNo) {
      setGameOver(true);
    }
  }, [timer]);
  const skipQuestion = () => {
    setIndexNo(indexNo + 1);
    setAnswer("");
    setTimer(30);
  };
  const handleAnswer = (choice, ans) => {
    setAnswer(choice);
    if (ans === choice) {
      setScore(score + 1);
    }
  };
  const renderTopReloadView = () => {
    return (
      <div className={styles.topContainerStyle}>
        <div
          className={styles.iconWrapperStyle}
          onClick={() => window.location.reload()}
        >
          <img src={ReloadIcon} className={styles.imgStyle} />
        </div>
        <div className={styles.iconWrapperStyle} onClick={() => navigate(-1)}>
          <img src={CloseIcon} className={styles.imgStyle} />
        </div>
      </div>
    );
  };
  const renderScoreContainer = () => {
    return (
      <div className={styles.scoreContainerStyle}>
        <p className={styles.levelTextStyle}>
          {indexNo + 1}
          <span className={styles.totalLevelsTextStyle}>
            /{questions.length}
          </span>
        </p>
        {/* <div className={styles.starsContainerStyle}>
          <div className={styles.starIconWrapperStyle}>
            <img src={StarYellowIcon} className={styles.imgStyle} />
          </div>
          <div className={styles.starIconWrapperStyle}>
            <img src={StarYellowIcon} className={styles.imgStyle} />
          </div>
          <div className={styles.starIconWrapperStyle}>
            <img src={StarEmptyIcon} className={styles.imgStyle} />
          </div>
        </div> */}
        <div className={styles.scorTextContainerStyle}>
          <div className={styles.starIconWrapperStyle}>
            <img src={ScoreCoinsIcon} className={styles.imgStyle} />
          </div>
          <p className={styles.scoreTextStyle}>0</p>
        </div>
      </div>
    );
  };
  const renderQuestionView = () => {
    return (
      <div className={styles.triviaContainerStyle}>
        {questions.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {index === indexNo && (
                <>
                  <div className={styles.queOuterContainerStyle}>
                    <div className={styles.questionContainerStyle}>
                      <p className={styles.questionTextSTyle}>{item.que}</p>
                    </div>
                  </div>
                  <div className={styles.answersMainContainerStyle}>
                    {item.ansChoise.map((choice, index) => {
                      return (
                        <button
                          key={index}
                          className={
                            answer === item.ans &&
                            answer === choice &&
                            answer !== ""
                              ? styles.correctAnsStyle
                              : answer !== item.ans &&
                                answer === choice &&
                                answer !== ""
                              ? styles.incorrectAnsStyle
                              : styles.answerContainerStyle
                          }
                          onClick={() => handleAnswer(choice, item.ans)}
                          disabled={answer !== "" ? true : false}
                        >
                          {choice}
                        </button>
                      );
                    })}
                  </div>
                </>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  };

  const renderTimerView = () => {
    return (
      <div className={styles.timerContainerStyle}>
        <p className={styles.timerTextStyle}>
          {false ? "00:00" : timerToString()}
        </p>
      </div>
    );
  };
  const renderAnswersView = () => {
    return (
      <div className={styles.triviaAnswersContainerStyle}>
        {questions.map((item, index) => {
          return (
            <div key={index}>
              <div className={styles.queOuterContainerStyle}>
                <div className={styles.questionContainerStyle}>
                  <p className={styles.questionTextSTyle}>{item.que}</p>
                </div>
              </div>
              <div className={styles.answersMainContainerStyle}>
                <button className={styles.correctAnsStyle} disabled={true}>
                  {item.ans}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div>
      {ansView ? (
        <div className={styles.gameAnswersContainerStyle}>
          {renderTopReloadView()}
          {renderAnswersView()}
        </div>
      ) : (
        <div className={styles.gameContainerStyle}>
          {renderTopReloadView()}
          {renderScoreContainer()}
          {renderQuestionView()}
          {renderTimerView()}
          <Button
            title={"Skip"}
            btnStyle={styles.btnStyles}
            onClick={() => skipQuestion()}
          />
          {gameOver && (
            <PopOver
              children={
                <div className={styles.popoverInsideContainerStyle}>
                  <p className={styles.gameTextSTyle}>Game Over</p>
                  <div className={styles.btnContainerStyle}>
                    <Button
                      title={"Answers"}
                      btnStyle={styles.btnStyle}
                      onClick={() => setAnsView(true)}
                    />
                    <Button
                      title={"LeaderBoard"}
                      btnStyle={styles.btnStyle}
                      onClick={() =>
                        navigate("/leaderboard", {
                          state: {
                            score: (score / questions.length) * 100,
                            gameName: "Trivia",
                            img: TriviaGameImg,
                          },
                        })
                      }
                    />
                  </div>
                </div>
              }
            />
          )}
          {!popOver && (
            <PopOver
              children={
                <div className={styles.popoverInsideContainerStyle}>
                  <div className={styles.closeIconContainerSTyle}>
                    <div
                      className={styles.iconWrapperStyle}
                      onClick={() => setPopOver(true)}
                    >
                      <img src={BlackCloseIcon} className={styles.imgStyle} />
                    </div>
                    <div className={styles.popoverHeaderContainerStyle}>
                      <p className={styles.popOverHeaderTextStyle}>
                        Player Details
                      </p>
                      <p className={styles.popOverDescTextStyle}>
                        Unleash Your Potential! Only the Highest Score
                        Guarantees Remarkable Gifts.
                      </p>
                    </div>
                  </div>
                  {step === 1 && (
                    <div className={styles.inputContainerStyle}>
                      <TextInput
                        label={"Email"}
                        type={"text"}
                        value={user.email}
                        placeholder={"Enter Email ID"}
                        onChange={(e) =>
                          setUser({ ...user, email: e.target.value })
                        }
                        // onChange={(e) => console.log(e.target.value)}
                      />
                      <TextInput
                        label={"Phone Number"}
                        type={"text"}
                        value={user.mobileNo}
                        placeholder={"Enter Phone number"}
                        onChange={(e) =>
                          setUser({ ...user, mobileNo: e.target.value })
                        }
                      />
                      <p
                        className={styles.skipBlackTextSTyle}
                        onClick={() => {
                          // setPopOver(true);
                          // localStorage.setItem("user", JSON.stringify(user));
                          setStep(step + 1);
                        }}
                      >
                        Skip
                      </p>
                      <Button
                        title={"Next"}
                        btnStyle={styles.btnNextStyle}
                        disabled={
                          user.email !== "" && user.mobileNo !== ""
                            ? false
                            : true
                        }
                        onClick={() => setStep(step + 1)}
                      />
                    </div>
                  )}
                  {step === 2 && (
                    <div className={styles.inputContainerStyle}>
                      <div className={styles.playerContainerStyle}>
                        <div className={styles.playerImgWrapperStyle}>
                          <img src={PlayerImage} className={styles.imgStyle} />
                        </div>
                        <div className={styles.playerNameContainerStyle}>
                          <div className={styles.iconWrapperStyle}>
                            <img
                              src={LightBlackLeftChevronIcon}
                              className={styles.imgStyle}
                            />
                          </div>
                          <p>Avathar</p>
                          <div className={styles.iconWrapperStyle}>
                            <img
                              src={RightBlackChevronIcon}
                              className={styles.imgStyle}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <TextInput
                          label={"Display Name"}
                          type={"text"}
                          value={user.name}
                          placeholder={"Enter display Name"}
                          onChange={(e) =>
                            setUser({ ...user, name: e.target.value })
                          }
                        />
                        <p className={styles.displayTextStyle}>
                          Your Display Name will be your Identity in the Game
                          Zone.
                        </p>
                      </div>
                      <p
                        className={styles.skipBlackTextSTyle}
                        onClick={() => {
                          localStorage.setItem("user", JSON.stringify(user));
                          setPopOver(true);
                        }}
                      >
                        Skip
                      </p>
                      <Button
                        title={"Continue"}
                        btnStyle={styles.btnNextStyle}
                        onClick={() => {
                          localStorage.setItem("user", JSON.stringify(user));
                          setPopOver(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              }
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TriviaGame;
