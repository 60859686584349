import React, { useState, useCallback, useEffect } from "react";
import styles from "./styles.module.css";
import { AiOutlineMenu } from "react-icons/ai";
import Button from "../../components/button";
import TextInput from "../../components/textinput";

import {
  Grid,
  ChoiceBoard,
  NoSolutionFoundModal,
  GameDetails,
  DifficultySelectionModal,
} from "../../components/index.js";

import {
  animateElement,
  arrayDeepCopy,
  checkBoard,
  checkPlayerWon,
  createSudokuGrid,
  solveSudoku,
} from "../../utility";

import useLocalStorage from "../../hooks/useLocalStorage";
import getHint from "../../utility/getHint";
import { useNavigate } from "react-router-dom";
import {
  CloseIcon,
  ReloadIcon,
  RightBlackChevronIcon,
  LightBlackLeftChevronIcon,
  BlackCloseIcon,
  PlayerImage,
  SudokuGameImg,
} from "../../resources/images/images";
import PopOver from "../../components/popover";
const easyMaxEmptyCells = 30;
const mediumMaxEmptyCells = 40;
const hardMaxEmptyCells = 50;

const SudukoGame = () => {
  const navigate = useNavigate();
  const [grid, setGrid] = useLocalStorage("currentGrid", null);
  const [startingGrid, setStartingGrid] = useLocalStorage("startingGrid", null);
  const [clickValue, setClickValue] = useLocalStorage("clickValue", 1);

  // Game Score logic
  const [gameMode, setGameMode] = useLocalStorage(
    "gameMode",
    mediumMaxEmptyCells
  );
  const [movesTaken, setMovesTaken] = useLocalStorage("movesTaken", 0);
  const [hintsTaken, setHintsTaken] = useLocalStorage("hintsTaken", 0);
  const [isPlayerWon, setIsPlayerWon] = useLocalStorage("playerWon", false);
  const [pressedSolve, setPressedSolve] = useLocalStorage(
    "pressedSolve",
    false
  );

  const [startTime, setStartTime] = useLocalStorage("startTime", () =>
    Date().toLocaleString()
  );

  const [showNoSolutionFoundModal, setShowNoSolutionFoundModal] =
    useState(false);
  const [showGameDetails, setShowGameDetails] = useState(false);
  const [showDifficultySelectionModal, setShowDifficultySelectionModal] =
    useState(false);
  const [timer, setTimer] = useState(0);
  const [reset, setReset] = useState(false);
  const [popOver, setPopOver] = useState(false);
  const [step, setStep] = useState(1);
  const [user, setUser] = useState({
    email: "",
    mobileNo: "",
    name: "",
  });
  const timerToString = useCallback(() => {
    let hours = ("0" + Math.floor(timer / 3600)).slice(-2);
    let minutes = ("0" + Math.floor(timer / 60)).slice(-2);
    let seconds = ("0" + (timer % 60)).slice(-2);
    return /*hours + ":" +*/ minutes + ":" + seconds;
  }, [timer]);
  useEffect(() => {
    if (localStorage.getItem("user")) {
      setPopOver(true);
    }
  }, [reset]);

  useEffect(() => {
    if (!showDifficultySelectionModal) {
      let timeOut = null;
      if (timer < 600) {
        timeOut = setTimeout(() => {
          setTimer((prev) => prev + 1);
        }, 1000);
      }
      return () => {
        if (reset) {
          clearTimeout(timeOut);
        }
      };
    }
  }, [timer, reset, showDifficultySelectionModal]);

  const handleSolve = () => {
    let solvedBoard = arrayDeepCopy(grid);
    let solvedStatus = solveSudoku(solvedBoard);
    if (solvedStatus === false) {
      setShowNoSolutionFoundModal((show) => !show);
      return;
    }

    let newHints = 0;
    for (let i = 0; i < 9; i++) {
      for (let j = 0; j < 9; j++) {
        if (grid[i][j].value === 0) {
          newHints++;
          solvedBoard[i][j].isHinted = true;
          solvedBoard[i][j].isModifiable = false;
        }
      }
    }

    setHintsTaken((hints) => hints + newHints);
    setIsPlayerWon(true);
    setShowGameDetails(true);
    setPressedSolve(true);
    setGrid(solvedBoard);
  };

  const handleHint = () => {
    // Checking if player has won
    if (isPlayerWon) return;

    // Getting hint
    let hintResponse = getHint(grid);

    // Checking if the grid cannot be solved
    if (hintResponse.solvedStatus === false) {
      setShowNoSolutionFoundModal((show) => !show);
      return;
    }

    // setting the result board
    setGrid(hintResponse.board);

    // Adding hint count
    setHintsTaken((hints) => hints + 1);

    // Checking if the player has won
    let playerWon = checkPlayerWon(hintResponse.board);
    if (playerWon) {
      setIsPlayerWon(true);
      setShowGameDetails(true);
      setTimer(0);
      setReset(true);
    }
  };

  const handleNewGame = (maxEmptyCellsCount) => {
    // Waiting for the function to return the grid
    let newSudokuGrid = createSudokuGrid(maxEmptyCellsCount);

    setStartingGrid(arrayDeepCopy(newSudokuGrid));
    setGrid(arrayDeepCopy(newSudokuGrid));

    // Setting the game mode with maxEmptyCellsCount
    setGameMode(maxEmptyCellsCount);

    // Reseting the values
    setMovesTaken(0);
    setHintsTaken(0);
    setIsPlayerWon(false);
    setPressedSolve(false);
    setStartTime(() => Date().toLocaleString());

    // Closing the difficulty modal and also setting the isLoading to false
    setShowDifficultySelectionModal((show) => !show);
    setReset(true);
    setTimer(0);
  };

  const handleClearBoard = () => {
    setIsPlayerWon(false);
    setGrid(arrayDeepCopy(startingGrid));
  };

  const handleCellClick = (row, column, isModifiable) => {
    if (!isModifiable) {
      animateElement(".grid-table", "headShake");
      return;
    }

    // moves registered when the value is not 0
    if (clickValue !== 0) setMovesTaken((moves) => moves + 1);

    let newGrid = arrayDeepCopy(grid);
    newGrid[row][column].value = clickValue;

    // Marking the node valid or invalid depending on the grid
    checkBoard(newGrid);

    // Checking if the player has won
    let playerWon = checkPlayerWon(newGrid);
    if (playerWon) {
      setIsPlayerWon(true);
      setShowGameDetails(true);
      setTimer(0);
      setReset(true);
    }

    // setting the value to the grid and also to the local storage
    setGrid(newGrid);
  };

  // If we donot have anything in the local storage
  if (grid == null && startingGrid == null) handleNewGame(gameMode);
  const renderTopReloadView = () => {
    return (
      <div className={styles.topContainerStyle}>
        <div
          className={styles.iconWrapperStyle}
          onClick={() => {
            setShowDifficultySelectionModal((show) => !show);
          }}
        >
          <img src={ReloadIcon} className={styles.imgStyle} />
        </div>
        <div className={styles.iconWrapperStyle} onClick={() => navigate(-1)}>
          <img src={CloseIcon} className={styles.imgStyle} />
        </div>
      </div>
    );
  };
  const renderTimerView = () => {
    return (
      <div className={styles.timerContainerStyle}>
        <p className={styles.timerTextStyle}>
          {isPlayerWon ? "00:00" : timerToString()}
        </p>
      </div>
    );
  };
  return (
    <div className={styles.gameContainerStyle}>
      {/* <div className="show-game-detail-container-button">
        <button onClick={() => setShowGameDetails((show) => !show)}>
          <AiOutlineMenu />
        </button>
      </div> */}
      {renderTopReloadView()}

      {showNoSolutionFoundModal && (
        <NoSolutionFoundModal
          closeModal={() => setShowNoSolutionFoundModal((show) => !show)}
        />
      )}

      {showDifficultySelectionModal && popOver && (
        <DifficultySelectionModal
          closeModal={() => {
            setShowDifficultySelectionModal((show) => !show);
          }}
          handleNewGame={handleNewGame}
          easyMaxEmptyCells={easyMaxEmptyCells}
          mediumMaxEmptyCells={mediumMaxEmptyCells}
          hardMaxEmptyCells={hardMaxEmptyCells}
        />
      )}

      {showGameDetails && (
        <GameDetails
          closeModal={() => {
            setShowGameDetails((show) => !show);
            setTimer(0);
            setReset(true);
          }}
          movesTaken={movesTaken}
          hintsTaken={hintsTaken}
          startTime={startTime}
          isPlayerWon={isPlayerWon}
          pressedSolve={pressedSolve}
          gameMode={gameMode}
          mediumMaxEmptyCells={mediumMaxEmptyCells}
          hardMaxEmptyCells={hardMaxEmptyCells}
        />
      )}
      <Grid handleCellClick={handleCellClick} grid={grid} />
      <ChoiceBoard setClickValue={setClickValue} selected={clickValue} />
      {renderTimerView()}
      <div className={styles.sudokuBtnContainerStyle}>
        <Button
          onClick={handleClearBoard}
          btnStyle={styles.btnStyle}
          title="Clear"
        />
        <Button
          onClick={handleSolve}
          btnStyle={styles.btnStyle}
          title="Solve"
        />
        <Button onClick={handleHint} btnStyle={styles.btnStyle} title="Hint" />
      </div>
      {(timer >= 600 || isPlayerWon === true) && (
        <PopOver
          children={
            <div className={styles.popoverInsideContainerStyle}>
              <p className={styles.gameTextSTyle}>
                {timer === 600 ? "Game Over" : "Congratulations! You won!"}
              </p>
              <div className={styles.btnContainerStyle}>
                <Button
                  title={"Restart"}
                  btnStyle={styles.btnStyle}
                  onClick={() =>
                    setShowDifficultySelectionModal((show) => !show)
                  }
                />
                <Button
                  title={"LeaderBoard"}
                  btnStyle={styles.btnStyle}
                  onClick={() =>
                    navigate("/leaderboard", {
                      state: {
                        score: 100 - (timer / 600) * 100,
                        gameName: "sudoku",
                        img: SudokuGameImg,
                      },
                    })
                  }
                />
              </div>
            </div>
          }
        />
      )}
      {!popOver && (
        <PopOver
          children={
            <div className={styles.popoverInsideContainerStyle}>
              <div className={styles.closeIconContainerSTyle}>
                <div
                  className={styles.iconWrapperStyle}
                  onClick={() => setPopOver(true)}
                >
                  <img src={BlackCloseIcon} className={styles.imgStyle} />
                </div>
                <div className={styles.popoverHeaderContainerStyle}>
                  <p className={styles.popOverHeaderTextStyle}>
                    Player Details
                  </p>
                  <p className={styles.popOverDescTextStyle}>
                    Unleash Your Potential! Only the Highest Score Guarantees
                    Remarkable Gifts.
                  </p>
                </div>
              </div>
              {step === 1 && (
                <div className={styles.inputContainerStyle}>
                  <TextInput
                    label={"Email (Optional)"}
                    type={"text"}
                    value={user.email}
                    placeholder={"Enter Email ID"}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                  />
                  <TextInput
                    label={"Phone Number"}
                    type={"text"}
                    value={user.mobileNo}
                    placeholder={"Enter Phone number"}
                    onChange={(e) =>
                      setUser({ ...user, mobileNo: e.target.value })
                    }
                  />
                  <p
                    className={styles.skipBlackTextSTyle}
                    onClick={() => {
                      // setPopOver(true);
                      // localStorage.setItem("user", JSON.stringify(user));
                      setStep(step + 1);
                    }}
                  >
                    Skip
                  </p>
                  <Button
                    title={"Next"}
                    btnStyle={styles.btnNextStyle}
                    disabled={
                      user.email !== "" && user.mobileNo !== "" ? false : true
                    }
                    onClick={() => setStep(step + 1)}
                  />
                </div>
              )}
              {step === 2 && (
                <div className={styles.inputContainerStyle}>
                  <div className={styles.playerContainerStyle}>
                    <div className={styles.playerImgWrapperStyle}>
                      <img src={PlayerImage} className={styles.imgStyle} />
                    </div>
                    <div className={styles.playerNameContainerStyle}>
                      <div className={styles.iconWrapperStyle}>
                        <img
                          src={LightBlackLeftChevronIcon}
                          className={styles.imgStyle}
                        />
                      </div>
                      <p>Avathar</p>
                      <div className={styles.iconWrapperStyle}>
                        <img
                          src={RightBlackChevronIcon}
                          className={styles.imgStyle}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <TextInput
                      label={"Display Name"}
                      type={"text"}
                      value={user.name}
                      placeholder={"Enter display Name"}
                      onChange={(e) =>
                        setUser({ ...user, name: e.target.value })
                      }
                    />
                    <p className={styles.displayTextStyle}>
                      Your Display Name will be your Identity in the Game Zone.
                    </p>
                  </div>
                  <p
                    className={styles.skipBlackTextSTyle}
                    onClick={() => {
                      localStorage.setItem("user", JSON.stringify(user));
                      setPopOver(true);
                    }}
                  >
                    Skip
                  </p>
                  <Button
                    title={"Continue"}
                    btnStyle={styles.btnNextStyle}
                    onClick={() => {
                      localStorage.setItem("user", JSON.stringify(user));
                      setPopOver(true);
                    }}
                  />
                </div>
              )}
            </div>
          }
        />
      )}
    </div>
  );
};

export default SudukoGame;
