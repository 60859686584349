import logo from "./logo.svg";
import "./App.css";
import AppRoutes from "./routes";
import { AppDataProvider } from "./providers/AppDataProvider";

function App() {
  return (
    <AppDataProvider>
      <AppRoutes />
    </AppDataProvider>
  );
}

export default App;
