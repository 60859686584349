import React, { useState, useEffect } from "react";
import Button from "../../components/button";
import {
  HeroImage,
  BlackRightArrowIcon,
  AboutImage,
  BlackDownArrowIcon,
  TopChevronArr,
  DownChevronArr,
  BlackSectionImage,
  CategoriesIcon,
  LikeIcon,
  PeopleIcon,
  PodiumIcon,
  SmartphoneIcon,
  MedalIcon,
  GameImage,
  QrCodeIcon,
} from "../../resources/images/images";
import { useAppData } from "../../providers/AppDataProvider";
import styles from "./styles.module.css";
import WebFooter from "../../components/webfooter";

const WebPage = () => {
  const { strings } = useAppData();
  const [indexNo, setIndexNo] = useState(0);
  const Data = [
    {
      header: strings.ourServiceSection.header1,
      desc: strings.ourServiceSection.desc1,
    },
    {
      header: strings.ourServiceSection.header2,
      desc: strings.ourServiceSection.desc1,
    },
    {
      header: strings.ourServiceSection.header3,
      desc: strings.ourServiceSection.desc1,
    },
    {
      header: strings.ourServiceSection.header4,
      desc: strings.ourServiceSection.desc1,
    },
  ];
  const FeaturesData = [
    {
      icon: CategoriesIcon,
      header: strings.featuresSection.header1,
      desc: strings.featuresSection.desc1,
    },
    {
      icon: LikeIcon,
      header: strings.featuresSection.header2,
      desc: strings.featuresSection.desc2,
    },
    {
      icon: PodiumIcon,
      header: strings.featuresSection.header3,
      desc: strings.featuresSection.desc3,
    },
    {
      icon: MedalIcon,
      header: strings.featuresSection.header4,
      desc: strings.featuresSection.desc5,
    },
    {
      icon: SmartphoneIcon,
      header: strings.featuresSection.header5,
      desc: strings.featuresSection.desc5,
    },
    {
      icon: PeopleIcon,
      header: strings.featuresSection.header6,
      desc: strings.featuresSection.desc6,
    },
  ];
  const Games = [
    { name: strings.openInMobileView.gameName, image: GameImage },
    { name: strings.openInMobileView.gameName, image: GameImage },
    { name: strings.openInMobileView.gameName, image: GameImage },
    { name: strings.openInMobileView.gameName, image: GameImage },
    { name: strings.openInMobileView.gameName, image: GameImage },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const renderTopNavView = () => {
    return (
      <div className={styles.topNavSectionStyle}>
        <p className={styles.logoTextStyle}>{strings.common.logoText}</p>
      </div>
    );
  };
  const renderMobileViewSection = () => {
    return (
      <div className={styles.mobileViewContainerStyle}>
        <h1 className={styles.mobileHeaderTextStyle}>
          {strings.openInMobileView.headerText}
        </h1>
        <p className={styles.mobileDescTextStyle}>
          {strings.openInMobileView.desc}
        </p>
        <Button
          btnStyle={styles.scanBtnStyle}
          imageUri={QrCodeIcon}
          title={"Scan QR-Code"}
        />
        <div className={styles.gamesContainerStyle}>
          {Games.map((item, index) => {
            return (
              <div key={index} className={styles.gameCardContainerStyle}>
                <div className={styles.gameImgWrapperStyle}>
                  <img src={item.image} className={styles.imageStyle} />
                </div>
                <p className={styles.gameNameTextStyle}>{item.name}</p>
              </div>
            );
          })}
        </div>
        <Button
          btnStyle={styles.mobileBtnStyle}
          imageUri={BlackDownArrowIcon}
          title={strings.openInMobileView.btnText}
        />
      </div>
    );
  };
  const renderHeroSection = () => {
    return (
      <div className={styles.heroMainContainerStyle}>
        <div className={styles.heroSectionTextContainerStyle}>
          <div className={styles.heroDescContainerStyle}>
            <h1 className={styles.heroHeaderTextSTyle}>
              {strings.topView.headerText}
              <span className={styles.heroHeaderBoldTextStyle}>
                {strings.topView.headerTextBlod}
              </span>
              {strings.topView.headerText2}
            </h1>
            <p className={styles.heroHeaderDescTextStyle}>
              {strings.topView.headerDesc}
            </p>
          </div>
        </div>
        <div className={styles.imageWrapperStyle}>
          <img src={HeroImage} className={styles.imageStyle} />
        </div>
      </div>
    );
  };
  const renderAboutUsSection = () => {
    return (
      <div className={styles.aboutSectionContainerStyle}>
        <p className={styles.sectionHeaderTextStyle}>
          {strings.aboutSection.aboutUs}
        </p>
        <div className={styles.aboutInsideContainerStyle}>
          <div className={styles.aboutImageWrapperStyle}>
            <img src={AboutImage} className={styles.imageStyle} />
          </div>
          <div className={styles.aboutTextContainerStyle}>
            <h2 className={styles.aboutHeaderTextStyle}>
              {strings.aboutSection.aboutHeader}
            </h2>

            <p className={styles.aboutDescTextStyle}>
              {strings.aboutSection.aboutDesc}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const renderOurServiceSection = () => {
    return (
      <div className={styles.serviceSectionContainerStyle}>
        <p className={styles.sectionHeaderTextStyle}>
          {strings.ourServiceSection.ourService}
        </p>
        {Data.map((item, index) => {
          return (
            <div
              key={index}
              className={styles.serviceDropDownContainerStyle}
              onClick={() => setIndexNo(index)}
            >
              <div className={styles.serviceDropItemDescContainerStyle}>
                <p className={styles.serviceDropItemHeaderTextStyle}>
                  {item.header}
                </p>
                <div className={styles.arrowImageWrapperStyle}>
                  <img
                    src={index === indexNo ? TopChevronArr : DownChevronArr}
                    className={styles.imageStyle}
                  />
                </div>
              </div>
              {index === indexNo && (
                <p className={styles.serviceItemDescTextStyle}>{item.desc}</p>
              )}
            </div>
          );
        })}
      </div>
    );
  };
  const renderBlackSection = () => {
    return (
      <div className={styles.blackSectionContainerStyle}>
        <div className={styles.blackSectionImageWrapperStyle}>
          <img src={BlackSectionImage} className={styles.imageStyle} />
        </div>
        <div className={styles.blackSectionTextContainerStyle}>
          <h2 className={styles.blackSectionHeaderTextStyle}>
            {strings.blackSection.header}
          </h2>
          <p className={styles.blackSectionDescTextStyle}>
            {strings.blackSection.desc}
          </p>
        </div>
      </div>
    );
  };
  const renderFeaturesSection = () => {
    return (
      <div className={styles.featuresSectionContainerStyle}>
        <p className={styles.sectionHeaderTextStyle}>
          {strings.featuresSection.features}
        </p>
        <div className={styles.featuresGridContainerStyle}>
          {FeaturesData.map((item, index) => {
            return (
              <div className={styles.featuresItemContainerStyle}>
                <div className={styles.featuresItemHeaderContainerStyle}>
                  <div className={styles.featuresImageWrapperStyle}>
                    <img src={item.icon} className={styles.imageStyle} />
                  </div>
                  <p className={styles.featuresHeaderTextStyle}>
                    {item.header}
                  </p>
                </div>
                <p className={styles.featuresDescTextStyle}>{item.desc}</p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  return (
    <div>
      {renderTopNavView()}
      <div className={styles.mainContainerStyle}>
        {renderMobileViewSection()}
        {renderHeroSection()}
        {renderAboutUsSection()}
        {renderOurServiceSection()}
        {renderBlackSection()}
        {renderFeaturesSection()}
      </div>
      <WebFooter />
    </div>
  );
};

export default WebPage;
