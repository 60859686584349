import React from "react";
import styles from "./styles.module.css";
import { FaEraser } from "react-icons/fa";

const CHOICES1 = [1, 2, 3, 4, 5];
const CHOICES2 = [6, 7, 8, 9];

const ChoiceBoard = ({ setClickValue, selected }) => {
  return (
    <div className={styles.numberBoardStyle}>
      <div className={styles.nubersFirstContainerStyle}>
        {CHOICES1.map((choice) => {
          return (
            <div
              className={
                choice === selected
                  ? styles.numberBtnSelectedStyle
                  : styles.numberBtnStyle
              }
              key={`key-1-${choice}`}
              onClick={() => setClickValue(choice)}
            >
              <p
                className={
                  choice === selected
                    ? styles.textSelectedColorStyle
                    : styles.textColorStyle
                }
              >
                {choice}
              </p>
            </div>
          );
        })}
      </div>
      <div className={styles.nubersFirstContainerStyle}>
        {CHOICES2.map((choice) => {
          return (
            <div
              className={
                choice === selected
                  ? styles.numberBtnSelectedStyle
                  : styles.numberBtnStyle
              }
              key={`key-2-${choice}`}
              onClick={() => setClickValue(choice)}
            >
              <p
                className={
                  choice === selected
                    ? styles.textSelectedColorStyle
                    : styles.textColorStyle
                }
              >
                {choice}
              </p>
            </div>
          );
        })}

        {/* Eraser class: value of zero */}
        <div
          className={
            selected === 0
              ? styles.numberBtnSelectedStyle
              : styles.numberBtnStyle
          }
          key={`key-2-${0}`}
          onClick={() => setClickValue(0)}
        >
          <FaEraser color={selected === 0 ? "black" : "white"} />
        </div>
      </div>
    </div>
  );
};

export default ChoiceBoard;
