const common = {
  logoText: "Q Games",
  home: "Home",
  games: "Games",
  about: "About",
  leaderBoard: "Leader Board",
  playNow: "Play Now",
};
const openInMobileView = {
  headerText:
    "Open the site on mobile to play games and win rewards at partnered restaurants!",
  desc: "Your All-in-One Destination for Fun and Rewards. Open on Mobile, Discover Joyful Playtime at Your Convenience!",
  btnText: "Scroll down to know more",
  gameName: "Bubble shooter",
};
const topView = {
  headerText: "Making Waiting Fun: ",
  headerTextBlod: "Play mobile Games,",
  headerText2: " Win Coupons, Embrace Joy.",
  headerDesc:
    "Your All-in-One Destination for Fun, Rewards, and Unforgettable Mobile Gaming Experiences!",
};
const aboutSection = {
  aboutUs: "About us",
  aboutHeader:
    "Your All-in-One Destination for Fun, Rewards, and Unforgettable Mobile Gaming Experiences!",
  aboutDesc:
    "Welcome to Q Games, where we redefine entertainment and elevate your gaming experience. Our passion for play drives us to curate a diverse collection of thrilling games that cater to all ages and preferences. From classic favorites to innovative challenges, we have something for everyone. With a commitment to fostering a vibrant gaming community, we create an atmosphere where players come first, engaging in friendly competition and forming lasting connections.",
};
const ourServiceSection = {
  ourService: "Our Services",
  header1: "Extensive Game Selection",
  header2: "Competitions and Rewards",
  header3: "Mobile-Optimized Experience",
  header4: "Community and Connection",
  desc1:
    "Dive into a world of captivating games that will keep you entertained for hours. We offer a vast library, featuring puzzle, action, strategy, and word games, among others. Get ready for an unparalleled gaming adventure!",
};
const blackSection = {
  header:
    " Q Games is your gateway to limitless fun, where you can embark on thrilling quests, conquer challenges, and unlock the rewards of triumph.",
  desc: `At Q Games, we're committed to excellence, innovation, and delivering unforgettable moments to our players. Join us in embracing the magic of gaming, where every click brings excitement and every victory is celebrated.`,
};
const featuresSection = {
  features: "Features",
  header1: "Diverse Game Categories",
  header2: "User-Friendly Interface",
  header3: "Leaderboard and Competitions",
  header4: "Rewarding Prizes",
  header5: "Mobile Play Anywhere",
  header6: "Accessible to All",
  desc1:
    "Choose from a wide range of game categories, including puzzles, action, adventure, arcade, and more.",
  desc2:
    "Our intuitive and user-friendly interface ensures a seamless and enjoyable gaming experience.",
  desc3:
    "Compete against players from around the world and see your name rise on the leaderboard.",
  desc4:
    "Aim for the top and win exclusive rewards and prizes. Be the best and reap the benefits of your gaming prowess.",
  desc5:
    "Take the excitement with you wherever you go. Our mobile-optimized platform lets you play anytime and any where",
  desc6: `Our games are designed to be inclusive and accessible to players of all skill levels. Whether you're a novice or an expert, there's a game for you.`,
};
const homePage = {
  headerText: {
    text1: "Play,",
    text2: " Compete, and Claim ",
    text3: "Weekly Rewards!",
  },
  desc: "Engage in thrilling competitions, dominate the games, and secure your spot among the weekly reward winners",
  headerText2: {
    text1: `“Game On! `,
    text2: `Compete for Rewards, Week after Week!”`,
  },
  popularGames: "Popular Games",
  popularDesc: "Elevate Your Wait with Interactive Games and Tasty Prizes",
  hangMan: "HangMan Game",
  gameName: "Bubble shooter",
  gameDesc: "Embark on a captivating bubble-popping adventure",
};
const footer = {
  headerText:
    "Get ready to play, challenge friends, and climb the leaderboard for ultimate glory!",
  contactUs: "Contact Us",
  mail: "Queue Games@gmail.com",
  mobile: "+232 ********45",
  privacyPolicy: "Privacy Policy",
  termsOfUse: "Terms of use",
  copyRights: "Copyright  2023 . All Rights Reserved.",
};
const gamesPage = {
  popularGames: "Popular Games",
  popularDesc: "Elevate Your Wait with Interactive Games and Tasty Prizes",
  gameName: "Bubble shooter",
  gameDesc: "Embark on a captivating bubble-popping adventure",
  popularGames: "Popular Games",
  mostPlayedGames: "Most Played Games",
  moreGames: "More Games",
};
const gameScreen = {
  gameName: "Bubble shooter",
  hangManGame: "HangMan Game",
  gameDesc: "Embark on a captivating bubble-popping adventure",
  highestScore: "Highest Score",
  review: "Review",
  players: "Players",
  single: "single",
  rating: "4.5",
  score: "150",
  aboutGame: "About this game",
  aboutDesc:
    "Bubble Shooter is a classic and popular puzzle game that has captured the hearts of players around the world.",
  gameImages: "Game Images",
  rewardsAndMore: "Rewards and more",
  rewardsDesc:
    "Play and Get High Score to win the coupons for this week. You can win upto 50% off or a free meal coupons.",
  rewardText: {
    text1: "win Coupons ",
    text2: "for free meal and more ",
    text3: "every week!",
  },
};
const leaderBoard = {
  gameName: "Bubble shooter",
  scoreBoard: "Scores and Leaderboard",
  gamerName: "@Gamer121",
  score: "Score",
  scoreNO: "0",
  topPerformanceText: "Top Performers Leaderboard",
  congrats: "Congratulations to our top performers!",
  all: "All",
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  rank: "Rank",
  playerName: "Player Name",
  score: "Score",
  No: "2",
};
const aboutPage = {
  headerText: {
    text1: "Unleashing ",
    text2: "Joyful Moments:",
    text3: " Your Destination for",
    text4: " Fun and Rewards!",
  },
  desc: "Welcome to our app, where waiting becomes a joyous adventure! We offer a wide variety of fun and addictive games to play while you wait. From classic puzzles to thrilling challenges, our collection has something for everyone.",
  cardText: {
    text1: "Explore a treasure trove of ",
    text2: "challenges, ",
    text3: "competitions, and ",
    text4: "rewards.",
  },
  headerText2:
    "Join the Quest for Fun - Q Games Puts the Joy Back in Gaming with Its Array of Engaging Features and Perks!",
  desc2:
    "Engage your mind and pass the time with endless entertainment. Moreover, we believe in rewarding our players! Compete in games, aim for high scores, and stand a chance to win exciting coupons and exclusive rewards. Our leaderboard fosters healthy competition, so you can showcase your skills and claim your well-deserved prizes.",
};
export const EnglishStrings = {
  common,
  openInMobileView,
  topView,
  aboutSection,
  ourServiceSection,
  blackSection,
  featuresSection,
  homePage,
  footer,
  gamesPage,
  gameScreen,
  leaderBoard,
  aboutPage,
};
