import React from "react";
// Css files
import styles from "./styles.module.css";
// import "animate.css";
// Components
import { Node } from "../index.js";

const Grid = ({ grid, handleCellClick }) => {
  return (
    <table className={styles.gridTableStyle}>
      <tbody className={styles.bodyStyle}>
        {grid &&
          grid.map((row, rowIndex) => {
            return (
              <tr className={styles.rowStyle} key={rowIndex}>
                {row.map((cell, columnIndex) => {
                  return (
                    <Node
                      key={rowIndex + "-" + columnIndex}
                      cell={cell}
                      handleClickCallback={handleCellClick}
                    />
                  );
                })}
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default Grid;
