import React, { useState } from "react";
import styles from "./styles.module.css";
import { useAppData } from "../../providers/AppDataProvider";
import {
  MenuIcon,
  CloseIcon,
  HangManGameImg,
  TriviaGameImg,
} from "../../resources/images/images";
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = (props) => {
  const { strings } = useAppData();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [leaderBoardView, setLeaderBoardView] = useState(false);
  return (
    <div className={styles.navMainContainerStyle}>
      <div className={styles.navBarInsideContainerStyle}>
        <p className={styles.logoTextStyle} onClick={() => navigate("/")}>
          {strings.common.logoText}
        </p>
        <div
          className={styles.menuImageWrapperStyle}
          onClick={() => setMenu(!menu)}
        >
          <img src={MenuIcon} className={styles.imgStyle} />
        </div>
      </div>
      <div
        className={
          menu
            ? styles.navMainOpenContainerStyle
            : styles.navMainCloseContainerStyle
        }
      >
        <div className={styles.navBarInsideContainerStyle}>
          <p
            className={menu ? styles.logoWhiteTextStyle : styles.logoTextStyle}
            onClick={() => navigate("/")}
          >
            {strings.common.logoText}
          </p>
          <div
            className={styles.menuImageWrapperStyle}
            onClick={() => setMenu(!menu)}
          >
            <img
              src={menu ? CloseIcon : MenuIcon}
              className={styles.imgStyle}
            />
          </div>
        </div>
        {menu && (
          <div className={styles.menuContainerStyle}>
            <div className={styles.menuItemWrapperStyle}>
              {pathname === "/" && (
                <div className={styles.leftDividerStyle}></div>
              )}
              <p className={styles.menuTextStyle} onClick={() => navigate("/")}>
                {strings.common.home}
              </p>
            </div>
            <div className={styles.menuItemWrapperStyle}>
              {pathname === "/gameszone" && (
                <div className={styles.leftDividerStyle}></div>
              )}
              <p
                className={styles.menuTextStyle}
                onClick={() => navigate("/gameszone")}
              >
                {strings.common.games}
              </p>
            </div>
            <div className={styles.menuItemWrapperStyle}>
              {pathname === "/aboutpage" && (
                <div className={styles.leftDividerStyle}></div>
              )}
              <p
                className={styles.menuTextStyle}
                onClick={() => navigate("/aboutpage")}
              >
                {strings.common.about}
              </p>
            </div>
            <div className={styles.leaderBoardStyle}>
              <div className={styles.menuItemWrapperStyle}>
                {pathname === "/leaderboard" && (
                  <div className={styles.leftDividerStyle}></div>
                )}
                <p
                  className={styles.menuTextStyle}
                  onClick={() => setLeaderBoardView(!leaderBoardView)}
                >
                  {strings.common.leaderBoard}
                </p>
              </div>
              {leaderBoardView && (
                <div className={styles.menuItemWrapperStyle}>
                  {props.gameName === "Hangman" && (
                    <div className={styles.leftDividerStyle}></div>
                  )}
                  <p
                    className={styles.menuTextStyle}
                    onClick={() => {
                      navigate("/leaderboard", {
                        state: {
                          score: 0,
                          gameName: "Hangman",
                          img: HangManGameImg,
                        },
                      });
                      setMenu(false);
                      setLeaderBoardView(false);
                    }}
                  >
                    Hangman
                  </p>
                </div>
              )}
              {leaderBoardView && (
                <div className={styles.menuItemWrapperStyle}>
                  {props.gameName === "Trivia" && (
                    <div className={styles.leftDividerStyle}></div>
                  )}
                  <p
                    className={styles.menuTextStyle}
                    onClick={() => {
                      navigate("/leaderboard", {
                        state: {
                          score: 0,
                          gameName: "Trivia",
                          img: TriviaGameImg,
                        },
                      });
                      setMenu(false);
                      setLeaderBoardView(false);
                    }}
                  >
                    Trivia
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
