import React from "react";
import styles from "./styles.module.css";

const VoucherCard = (props) => {
  return (
    <div
      className={[props.containerStyle, styles.voucherContainerStyle].join(" ")}
    >
      <p
        className={[props.boldTextSTyle, styles.voucherBoldTextStyle].join(" ")}
      >
        {props.boldText}
        <span className={[props.textStyle, styles.voucherTextStyle].join(" ")}>
          {props.lightText}
        </span>
      </p>
      <div className={styles.topVoucherImgWrapperStyle}>
        <img src={props.voucherImgUri} className={styles.imgStyle} />
      </div>
    </div>
  );
};

export default VoucherCard;
