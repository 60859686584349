import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Button from "../../components/button";
import { useAppData } from "../../providers/AppDataProvider";
import styles from "./styles.module.css";
import { BlackDownArrowIcon } from "../../resources/images/images";

const AboutPage = () => {
  const { strings } = useAppData();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const renderTopView = () => {
    return (
      <div className={styles.topViewContainerStyle}>
        <h1 className={styles.headerTextStyle}>
          {strings.aboutPage.headerText.text1}
          <span className={styles.headerBoldTextStyle}>
            {strings.aboutPage.headerText.text2}
          </span>
          {strings.aboutPage.headerText.text3}
          <span className={styles.headerBoldTextStyle}>
            {strings.aboutPage.headerText.text4}
          </span>
        </h1>
        <p className={styles.headerDescTextStyle}>{strings.aboutPage.desc}</p>
      </div>
    );
  };
  const renderCardView = () => {
    return (
      <div className={styles.cardViewStyle}>
        <p className={styles.cardTextStyle}>
          {strings.aboutPage.cardText.text1}
          <span className={styles.cardBoldTextStyle}>
            {strings.aboutPage.cardText.text2}
          </span>
          {strings.aboutPage.cardText.text3}
          <span className={styles.cardBoldTextStyle}>
            {strings.aboutPage.cardText.text4}
          </span>
        </p>
      </div>
    );
  };
  const renderBottomView = () => {
    return (
      <div className={styles.topViewContainerStyle}>
        <h1 className={styles.headerTextStyle}>
          {strings.aboutPage.headerText2}
        </h1>
        <p className={styles.headerDescTextStyle}>{strings.aboutPage.desc2}</p>
      </div>
    );
  };
  return (
    <div>
      <Navbar />
      <div className={styles.mainContainerStyle}>
        {renderTopView()}
        <Button title={"Know more"} imageUri={BlackDownArrowIcon} />
        {renderCardView()}
        {renderBottomView()}
      </div>
      <Footer />
    </div>
  );
};

export default AboutPage;
