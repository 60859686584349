import React from "react";
import Button from "../button";
import styles from "./styles.module.css";

const GameCard = (props) => {
  return (
    <div
      className={[props.cardContainerStyle, styles.gameCardContainerStyle].join(
        " "
      )}
      onClick={props.onClick}
    >
      <div
        className={[props.gameImgWrapperStyle, styles.gameImgWrapperStyle].join(
          " "
        )}
      >
        <img src={props.gameImgUri} className={styles.imgStyle} />
      </div>
      <div className={styles.gameTextContainerStyle}>
        <div
          className={[
            props.gameNameContainerStyle,
            styles.gameTextInsideContainerStyle,
          ].join(" ")}
        >
          <p
            className={[props.gameNameTextStyle, styles.gameNameTextStyle].join(
              " "
            )}
          >
            {props.gameName}
          </p>
          <p
            className={[props.gameDescTextStyle, styles.gameDescTextStyle].join(
              " "
            )}
          >
            {props.gameDesc}
          </p>
        </div>
        <Button
          btnStyle={[props.buttonStyle, styles.buttonStyle].join(" ")}
          title={"Play Now"}
        />
      </div>
    </div>
  );
};

export default GameCard;
