import React, { useEffect } from "react";
import Navbar from "../../components/navbar";
import Footer from "../../components/footer";
import Button from "../../components/button";
import {
  GameImage,
  UserIcon,
  RatingStartIcon,
  ScoreCoinsIcon,
  DownChevronArr,
  RewardImg,
  HangmanSuccess,
  HangManGameImg,
  TriviaGameImg,
  HangManWhiteGameImg,
  SudokuGameImg,
} from "../../resources/images/images";
import { useAppData } from "../../providers/AppDataProvider";
import styles from "./styles.module.css";
import RewardCard from "../../components/rewardcard";
import { useNavigate, useLocation } from "react-router-dom";

const GamePage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { strings } = useAppData();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const renderGameDetails = () => {
    return (
      <div className={styles.gamesMainContainerStyle}>
        <div className={styles.gameInsideContainerStyle}>
          {renderPlayGame()}
          {/* {renderGameDesc()}
          {renderGameImages()} */}
        </div>
        {renderRewardsView()}
      </div>
    );
  };
  const renderPlayGame = () => {
    return (
      <div className={styles.playContainerStyle}>
        {renderGamePlayView()}
        {renderRatingsContainer()}
        <Button
          btnStyle={styles.buttonStyle}
          title={"Play Now"}
          onClick={() =>
            navigate(
              location.state === "hangman"
                ? "/hangmangame"
                : location.state === "sudoku"
                ? "/sudokugame"
                : "/triviagame"
            )
          }
        />
      </div>
    );
  };
  const renderGamePlayView = () => {
    return (
      <div className={styles.playGameContainerStyle}>
        <div className={styles.gameImgWrapperStyle}>
          <img
            src={
              location.state === "hangman"
                ? HangManWhiteGameImg
                : location.state === "sudoku"
                ? SudokuGameImg
                : TriviaGameImg
            }
            className={styles.imgStyle}
          />
        </div>
        <div className={styles.gameTextContainerStyle}>
          <p className={styles.gameNameTextStyle}>
            {location.state === "hangman"
              ? strings.gameScreen.hangManGame
              : location.state === "sudoku"
              ? "Sudoku"
              : "Trivia"}
          </p>
          <p className={styles.gameDescTextStyle}>
            {strings.gameScreen.gameDesc}
          </p>
        </div>
      </div>
    );
  };
  const renderRatingsContainer = () => {
    return (
      <div className={styles.gameRatingsContainerStyle}>
        <div className={styles.itemContainerStyle}>
          <p className={styles.itemHeaderTextStyle}>
            {strings.gameScreen.highestScore}
          </p>
          <div className={styles.itemScoreContainerStyle}>
            <div className={styles.iconWrapperStyle}>
              <img src={ScoreCoinsIcon} className={styles.imgStyle} />
            </div>
            <p className={styles.itemSecondTextStyle}>
              {strings.gameScreen.score}
            </p>
          </div>
        </div>
        {renderDivider()}
        <div className={styles.itemContainerStyle}>
          <p className={styles.itemHeaderTextStyle}>
            {strings.gameScreen.review}
          </p>
          <div className={styles.itemScoreContainerStyle}>
            <div className={styles.iconWrapperStyle}>
              <img src={RatingStartIcon} className={styles.imgStyle} />
            </div>
            <p className={styles.itemSecondTextStyle}>
              {strings.gameScreen.rating}
            </p>
          </div>
        </div>
        {renderDivider()}
        <div className={styles.itemContainerStyle}>
          <p className={styles.itemHeaderTextStyle}>
            {strings.gameScreen.players}
          </p>
          <div className={styles.itemScoreContainerStyle}>
            <div className={styles.iconWrapperStyle}>
              <img src={UserIcon} className={styles.imgStyle} />
            </div>
            <p className={styles.itemSecondTextStyle}>
              {strings.gameScreen.single}
            </p>
          </div>
        </div>
      </div>
    );
  };
  const renderGameDesc = () => {
    return (
      <div className={styles.gameDescContainerStyle}>
        <div className={styles.gameAboutItemStyle}>
          <p className={styles.aboutHeaderTextStyle}>
            {strings.gameScreen.aboutGame}
          </p>
          <div className={styles.downArrWrapperStyle}>
            <img src={DownChevronArr} className={styles.imgStyle} />
          </div>
        </div>
        <p className={styles.gameAboutDescTextStyle}>
          {strings.gameScreen.aboutDesc}
        </p>
      </div>
    );
  };
  const renderGameImages = () => {
    return (
      <div className={styles.gameImgMainContainerStyle}>
        <p className={styles.gameImgTextStyle}>
          {strings.gameScreen.gameImages}
        </p>
        <div className={styles.imagesContainerStyle}>
          <div className={styles.gameImageWrapperStyle}>
            <img src={GameImage} className={styles.imgStyle} />
          </div>
          <div className={styles.gameImageWrapperStyle}>
            <img src={GameImage} className={styles.imgStyle} />
          </div>
          <div className={styles.gameImageWrapperStyle}>
            <img src={GameImage} className={styles.imgStyle} />
          </div>
        </div>
      </div>
    );
  };
  const renderDivider = () => {
    return <div className={styles.dividerStyle}></div>;
  };
  const renderRewardsView = () => {
    return (
      <div className={styles.rewardsContainerStyle}>
        <div className={styles.rewardsTextContainerStyle}>
          <p className={styles.gameImgTextStyle}>
            {strings.gameScreen.rewardsAndMore}
          </p>
          <p className={styles.gameAboutDescTextStyle}>
            {strings.gameScreen.rewardsDesc}
          </p>
        </div>
        <RewardCard
          imageUri={RewardImg}
          boldText1={strings.gameScreen.rewardText.text1}
          boldText2={strings.gameScreen.rewardText.text3}
          normalText1={strings.gameScreen.rewardText.text2}
        />
      </div>
    );
  };
  return (
    <div>
      <Navbar />
      {renderGameDetails()}
      <Footer />
    </div>
  );
};

export default GamePage;
